import { FEATURE_NAME, FEATURE_NAMES } from '../FeatureName.enum'

export enum FeaturitFlag {
  DELEGATED_TENANTS = 'DELEGATED_TENANTS',
  API_KEYS_V2 = 'API_KEYS_V2',
  POLICY_V2 = 'POLICY_V2',
  WORKSPACES_GROUPS_DETAILS_WORKSPACES_V2 = 'WORKSPACES_GROUPS_DETAILS_WORKSPACES_V2',
  ORGANIZATION_LIST_V2 = 'ORGANIZATION_LIST_V2',
}

export const FeatureFlags: FEATURE_NAME[] = [
  FEATURE_NAMES.INSTALLED_APPS_READ,
  FEATURE_NAMES.LICENSES_READ,
  FEATURE_NAMES.WORKSPACES_V2_READ,
  FEATURE_NAMES.ODIN_REPORTS_READ,
  FEATURE_NAMES.VIEW_AS_END_USER,
  FEATURE_NAMES.WORKSPACE_GROUP_SCHEDULE_READ
]
