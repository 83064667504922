import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { BusinessType } from 'app/models/business/BusinessType'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { useFeaturit } from 'app/globals/context/FeaturitProvider'
import { FeaturitFlag } from 'app/permissions/authorizer/FeatureFlags'

const getOrganizationsForSelectionQuery = gql`
  query getBusiness($onlyActive: Boolean) {
    getBusiness(onlyActive: $onlyActive) {
      _id
      name
      type
      isTrial
      active
      uniqueCode
      delegatedOrganizations
      isDelegatedOrganization
      modules {
        type
        url
      }
    }
  }
`

interface Response {
  getBusiness: SelectableOrganization[]
}

export interface SelectableOrganization {
  _id: string
  name: string
  type: BusinessType
  isTrial: boolean
  active: boolean
  uniqueCode: string
  modules: { type: string; url: string }[]
  delegatedOrganizations: string[]
  isDelegatedOrganization: boolean
}

function getOrganizationsForSelection(onlyActive = false): Promise<SelectableOrganization[]> {
  return graphClient
    .request(getOrganizationsForSelectionQuery, { onlyActive })
    .then((response) => response.getBusiness)
    .then((organizations) =>
      organizations
        .map((ele: SelectableOrganization) => {
          return {
            ...ele,
            delegatedOrganizations: [],
          }
        })
        .sort((orgA: SelectableOrganization, orgB: SelectableOrganization) => {
          const nameA = orgA.name.toLowerCase()
          const nameB = orgB.name.toLowerCase()
          if (nameA > nameB) return 1
          if (nameB > nameA) return -1
          return 0
        })
    )
}

async function getOrganizationsForSelectionV2(onlyActive = false): Promise<SelectableOrganization[]> {
  const organizations = await graphClient
    .request<Response>(getOrganizationsForSelectionQuery, { onlyActive })
    .then((response) => response.getBusiness)

  const mapMainOrganizations = organizations
    .filter((org) => org.delegatedOrganizations?.length)
    .reduce<Record<string, string>>((acc, org) => {
      const delegated = org.delegatedOrganizations
        .filter((id) => id !== org._id)
        .reduce((acc, orgId) => {
          return { ...acc, [orgId]: org.name }
        }, {})

      return {
        ...acc,
        ...delegated,
      }
    }, {})

  return organizations
    .map((org) => {
      return {
        ...org,
        name: mapMainOrganizations[org._id] ? `${mapMainOrganizations[org._id]} > ${org.name}` : org.name,
      }
    })
    .sort((orgA: SelectableOrganization, orgB: SelectableOrganization) => {
      return orgA.name.toLowerCase().localeCompare(orgB.name.toLowerCase())
    })
}

export function useGetOrganizationsForSelection(onlyActive = false): UseQueryResult<SelectableOrganization[]> {
  const featureit = useFeaturit()
  const v2 = featureit.isActive(FeaturitFlag.DELEGATED_TENANTS)

  return useQuery(
    ['business', { forSelection: true, onlyActive, v2 }],
    () => {
      if (v2) {
        return getOrganizationsForSelectionV2(onlyActive)
      }

      return getOrganizationsForSelection(onlyActive)
    },
    { refetchOnWindowFocus: false }
  )
}
