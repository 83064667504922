enum Module {
  ACTIVATION = 'ACTIVATION',
  ALL_ORGANIZATION = 'ALL_ORGANIZATION',
  BUILD_OPERATIONS_LOG = 'BUILD_OPERATIONS_LOG',
  ELIGIBLE_PRODUCT = 'ELIGIBLE_PRODUCT',
  ENABLED_MICROSERVICE = 'ENABLED_MICROSERVICE',
  ENVIRONMENT = 'ENVIRONMENT',
  ENVIRONMENT_AGENT_SETTINGS = 'ENVIRONMENT_AGENT_SETTINGS',
  ENVIRONMENT_BASELINE = 'ENVIRONMENT_BASELINE',
  FLOWS = 'FLOWS',
  HOME = 'HOME',
  INSTALLED_APPS = 'INSTALLED_APPS',
  INTEGRATIONS = 'INTEGRATIONS',
  MICROSERVICES = 'MICROSERVICES',
  MODULE = 'MODULE',
  OPERATIONS = 'OPERATIONS',
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATION_PARTNER = 'ORGANIZATION_PARTNER',
  ORGANIZATION_TYPE = 'ORGANIZATION_TYPE',
  PATCH_MANAGEMENT = 'PATCH_MANAGEMENT',
  POLICY = 'POLICY',
  REPORTING_GROUP = 'REPORTING_GROUP',
  REPORTING_GROUP_AGENT_SETTINGS = 'REPORTING_GROUP_AGENT_SETTINGS',
  REPORTS = 'REPORTS',
  ROLE = 'ROLE',
  TENANT = 'TENANT',
  USER = 'USER',
  USER_ADMIN_ROLE = 'USER_ADMIN_ROLE',
  USER_JOURNEY = 'USER_JOURNEY',
  WORKSPACE_GROUPS = 'WORKSPACE_GROUPS',
  WORKSPACES = 'WORKSPACES',
  WORKSPACES_V2 = 'WORKSPACES_V2',
  VIEW_AS_END_USER = 'VIEW_AS_END_USER',
}

enum Verb {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  READ = 'READ',
  UPDATE = 'UPDATE',
}

type FeatureName = `${Module}_${Verb}`

export const FEATURE_NAMES = {
  ACTIVATION_READ: 'ACTIVATION_READ',
  ALL_ORGANIZATION_READ: 'ALL_ORGANIZATION_READ',
  BUILD_OPERATIONS_LOG_READ: 'BUILD_OPERATIONS_LOG_READ',
  ELIGIBLE_PRODUCT_CREATE: 'ELIGIBLE_PRODUCT_CREATE',
  ELIGIBLE_PRODUCT_DELETE: 'ELIGIBLE_PRODUCT_DELETE',
  ELIGIBLE_PRODUCT_READ: 'ELIGIBLE_PRODUCT_READ',
  ELIGIBLE_PRODUCT_UPDATE: 'ELIGIBLE_PRODUCT_UPDATE',
  ENABLED_MICROSERVICE_READ: 'ENABLED_MICROSERVICE_READ',
  ENVIRONMENT_AGENT_SETTINGS_READ: 'ENVIRONMENT_AGENT_SETTINGS_READ',
  ENVIRONMENT_AGENT_SETTINGS_UPDATE: 'ENVIRONMENT_AGENT_SETTINGS_UPDATE',
  ENVIRONMENT_BASELINE_READ: 'ENVIRONMENT_BASELINE_READ',
  ENVIRONMENT_BASELINE_UPDATE: 'ENVIRONMENT_BASELINE_UPDATE',
  ENVIRONMENT_CREATE: 'ENVIRONMENT_CREATE',
  ENVIRONMENT_READ: 'ENVIRONMENT_READ',
  ENVIRONMENT_UPDATE: 'ENVIRONMENT_UPDATE',
  FLOWS_CREATE: 'FLOWS_CREATE',
  FLOWS_READ: 'FLOWS_READ',
  FLOWS_UPDATE: 'FLOWS_UPDATE',
  HOME_READ: 'HOME_READ',
  INSTALLED_APPS_READ: 'INSTALLED_APPS_READ',
  INTEGRATIONS_CREATE: 'INTEGRATIONS_CREATE',
  INTEGRATIONS_READ: 'INTEGRATIONS_READ',
  INTEGRATIONS_UPDATE: 'INTEGRATIONS_UPDATE',
  LICENSES_READ: 'LICENSES_READ',
  LICENSES_CREATE: 'LICENSES_CREATE',
  LICENSES_UPDATE: 'LICENSES_UPDATE',
  MICROSERVICES_CREATE: 'MICROSERVICES_CREATE',
  MICROSERVICES_DELETE: 'MICROSERVICES_DELETE',
  MICROSERVICES_READ: 'MICROSERVICES_READ',
  MICROSERVICES_UPDATE: 'MICROSERVICES_UPDATE',
  MODULE_CREATE: 'MODULE_CREATE',
  MODULE_DELETE: 'MODULE_DELETE',
  MODULE_READ: 'MODULE_READ',
  MODULE_UPDATE: 'MODULE_UPDATE',
  OPERATIONS_READ: 'OPERATIONS_READ',
  ORGANIZATION_CREATE: 'ORGANIZATION_CREATE',
  ORGANIZATION_DELETE: 'ORGANIZATION_DELETE',
  ORGANIZATION_PARTNER_UPDATE: 'ORGANIZATION_PARTNER_UPDATE',
  ORGANIZATION_READ: 'ORGANIZATION_READ',
  ORGANIZATION_TYPE_UPDATE: 'ORGANIZATION_TYPE_UPDATE',
  ORGANIZATION_UPDATE: 'ORGANIZATION_UPDATE',
  PATCH_MANAGEMENT_CREATE: 'PATCH_MANAGEMENT_CREATE',
  PATCH_MANAGEMENT_DELETE: 'PATCH_MANAGEMENT_DELETE',
  PATCH_MANAGEMENT_READ: 'PATCH_MANAGEMENT_READ',
  PATCH_MANAGEMENT_UPDATE: 'PATCH_MANAGEMENT_UPDATE',
  POLICY_CREATE: 'POLICY_CREATE',
  POLICY_DELETE: 'POLICY_DELETE',
  POLICY_READ: 'POLICY_READ',
  POLICY_UPDATE: 'POLICY_UPDATE',
  REPORTING_GROUP_AGENT_SETTINGS_READ: 'REPORTING_GROUP_AGENT_SETTINGS_READ',
  REPORTING_GROUP_AGENT_SETTINGS_UPDATE: 'REPORTING_GROUP_AGENT_SETTINGS_UPDATE',
  REPORTING_GROUP_CREATE: 'REPORTING_GROUP_CREATE',
  REPORTING_GROUP_DELETE: 'REPORTING_GROUP_DELETE',
  REPORTING_GROUP_READ: 'REPORTING_GROUP_READ',
  REPORTING_GROUP_UPDATE: 'REPORTING_GROUP_UPDATE',
  REPORTS_READ: 'REPORTS_READ',
  ROLE_CREATE: 'ROLE_CREATE',
  ROLE_DELETE: 'ROLE_DELETE',
  ROLE_READ: 'ROLE_READ',
  ROLE_UPDATE: 'ROLE_UPDATE',
  TENANT_CREATE: 'TENANT_CREATE',
  TENANT_DELETE: 'TENANT_DELETE',
  TENANT_READ: 'TENANT_READ',
  TENANT_UPDATE: 'TENANT_UPDATE',
  USER_ADMIN_ROLE_UPDATE: 'USER_ADMIN_ROLE_UPDATE',
  USER_CREATE: 'USER_CREATE',
  USER_DELETE: 'USER_DELETE',
  USER_JOURNEY_READ: 'USER_JOURNEY_READ',
  USER_READ: 'USER_READ',
  USER_UPDATE: 'USER_UPDATE',
  WORKSPACE_GROUPS_CREATE: 'WORKSPACE_GROUPS_CREATE',
  WORKSPACE_GROUPS_DELETE: 'WORKSPACE_GROUPS_DELETE',
  WORKSPACE_GROUPS_READ: 'WORKSPACE_GROUPS_READ',
  WORKSPACE_GROUPS_UPDATE: 'WORKSPACE_GROUPS_UPDATE',
  WORKSPACES_READ: 'WORKSPACES_READ',
  INSTALLED_APPS: 'INSTALLED_APPS',
  ODIN_REPORTS_READ: 'ODIN_REPORTS_READ',
  WORKSPACES_V2_READ: 'WORKSPACES_V2_READ',
  WORKSPACES_V2_DELETE: 'WORKSPACES_V2_DELETE',
  WORKSPACES_V2_CREATE: 'WORKSPACES_V2_CREATE',
  WORKSPACES_V2_UPDATE: 'WORKSPACES_V2_UPDATE',
  VIEW_AS_END_USER: 'VIEW_AS_END_USER',
  WORKSPACE_GROUP_SCHEDULE_READ: 'WORKSPACE_GROUP_SCHEDULE_READ'
} as const

type FEATURE_KEYS = (typeof FEATURE_NAMES)[keyof typeof FEATURE_NAMES]
export type FEATURE_NAME = (typeof FEATURE_NAMES)[FEATURE_KEYS]
