import { Featurit } from 'featurit-sdk-js-browser'
import { RolesOnBusiness } from '../../models/user/RolesOnBusiness'
import { FEATURE_NAME } from '../FeatureName.enum'
import { roleFeature, RoleName } from '../RoleFeature.enum'
import { authorizationExceptions } from './AuthorizationExceptions'
import { FeatureFlags } from './FeatureFlags'
import { SelectableOrganization } from 'app/hooks/organization/useGetOrganizationsForSelection'

const isFeatureEnabledForPortalRole = (portalRole: RoleName, feature: FEATURE_NAME) => {
  return roleFeature[portalRole].includes(feature)
}

const isFeatureEnableForExtendedCondition = (
  rolesOnBusiness: RolesOnBusiness,
  feature: FEATURE_NAME,
  selectedOrganization?: SelectableOrganization
) => {
  if (!authorizationExceptions[feature]) {
    return true
  }

  return authorizationExceptions[feature]?.(rolesOnBusiness, selectedOrganization)
}

const isFeatureFlagEnabled = (feature: FEATURE_NAME, featureit: Featurit) => {
  if (!FeatureFlags.includes(feature)) {
    return true
  }

  return featureit.isActive(feature)
}

export const authorizeFeature = function (
  rolesOnBusiness: RolesOnBusiness,
  feature: FEATURE_NAME,
  featureit: Featurit,
  selectedOrganization?: SelectableOrganization
) {
  if (!isFeatureFlagEnabled(feature, featureit)) {
    return false
  }

  if (!isFeatureEnabledForPortalRole(rolesOnBusiness.portal as RoleName, feature)) {
    return false
  }

  return isFeatureEnableForExtendedCondition(rolesOnBusiness, feature, selectedOrganization)
}
