import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { FEATURE_NAME } from './FeatureName.enum'
import { roleFeature } from './RoleFeature.enum'
import { authorizeFeature } from './authorizer/authorizeFeature'
import { useFeaturit } from 'app/globals/context/FeaturitProvider'

export const useFeatureRender = (feature?: FEATURE_NAME) => {
  const { rolesOnBusiness, selectedOrganization } = useOrganizationSelection()
  const featureit = useFeaturit()

  if (!rolesOnBusiness || (rolesOnBusiness && !(rolesOnBusiness.portal in roleFeature))) {
    return {
      hasFeature: false,
      checkFeature: () => false,
    }
  }

  return {
    hasFeature: authorizeFeature(rolesOnBusiness, feature!, featureit, selectedOrganization),
    checkFeature: (feature: FEATURE_NAME) => {
      return authorizeFeature(rolesOnBusiness, feature, featureit, selectedOrganization)
    },
  }
}
