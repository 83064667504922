import { useMySelfOn } from 'app/hooks/me/useMySelfOn'
import { RolesOnBusiness } from 'app/models/user/RolesOnBusiness'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import {
  SelectableOrganization,
  useGetOrganizationsForSelection,
} from '../../hooks/organization/useGetOrganizationsForSelection'
import { useMyOrganization } from '../../hooks/organization/useMyOrganization'
import { useMySettings } from '../../hooks/userSettings/useMySettings'

export interface GlobalContextOrganizationSelection {
  selectedOrganizationId?: string
  selectedOrganization: SelectableOrganization
  setSelectedOrganizationId: Dispatch<SetStateAction<string | undefined>>
  rolesOnBusiness?: RolesOnBusiness
}

export const globalContextOrganizationSelection = (navigate: NavigateFunction): GlobalContextOrganizationSelection => {
  const { mySettings } = useMySettings()
  const { data: loggedUserOrganization } = useMyOrganization()
  const { data: selectableOrganizations } = useGetOrganizationsForSelection(true)
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | undefined>()
  const { data, error } = useMySelfOn(selectedOrganizationId)

  useEffect(() => {
    if (error?.response?.errors?.[0]?.extensions?.code === 'UNAUTHORIZED') {
      setInitialOrganizationSelection()
      navigate('/home')
    }
  }, [error])

  const isOrganizationSelectable = (organizationId: string) => {
    return selectableOrganizations?.some((org) => org._id === organizationId)
  }
  const setInitialOrganizationSelection = () => {
    if (mySettings === undefined || !loggedUserOrganization || !selectableOrganizations) {
      return
    }

    if (mySettings?.defaultOrganizationSelected && isOrganizationSelectable(mySettings.defaultOrganizationSelected)) {
      setSelectedOrganizationId(mySettings?.defaultOrganizationSelected)
      return
    }

    if (isOrganizationSelectable(loggedUserOrganization._id)) {
      setSelectedOrganizationId(loggedUserOrganization._id)
      return
    }

    setSelectedOrganizationId(selectableOrganizations[0]._id)
  }

  useEffect(() => {
    if (selectedOrganizationId) {
      return
    }
    setInitialOrganizationSelection()
  }, [loggedUserOrganization, mySettings, selectableOrganizations])

  return {
    selectedOrganizationId,
    selectedOrganization: selectableOrganizations?.find((org) => org._id === selectedOrganizationId)!,
    setSelectedOrganizationId,
    rolesOnBusiness:
      data &&
      RolesOnBusiness.fromPrimitives({
        ...RolesOnBusiness.LowestPermission().toPrimitives(),
        ...data,
      }),
  }
}
