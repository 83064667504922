import type { RouteObject } from 'react-router-dom'

export const microservicesRoutes: RouteObject[] = [
  {
    path: '/microservices-enabled',
    lazy: () => import(/* webpackPrefetch: true */ 'app/pages/microservices/enabled/EnabledMicroservicesFeature'),
  },
  {
    path: '/microservices-marketplace',
    lazy: () => import(/* webpackPrefetch: true */ 'app/pages/microservices/marketplace/MicroservicesMarketplacePage'),
  },
  {
    path: '/microservices-designer',
    children: [
      {
        index: true,
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/microservices/designer/MicroservicesDesignerPage'),
      },
      {
        path: 'create',
        lazy: () =>
          import(/* webpackPrefetch: true */ 'app/pages/microservices/create/MicroserviceCreationPage').then((m) => ({
            Component: m.MicroserviceStandardCreationPage,
          })),
      },
      {
        path: 'create/condition',
        lazy: () =>
          import(/* webpackPrefetch: true */ 'app/pages/microservices/create/MicroserviceCreationPage').then((m) => ({
            Component: m.MicroserviceConditionCreationPage,
          })),
      },
      {
        path: 'create/custom-fields',
        lazy: () =>
          import(/* webpackPrefetch: true */ 'app/pages/microservices/create/MicroserviceCreationPage').then((m) => ({
            Component: m.MicroserviceCustomFieldsCreationPage,
          })),
      },
      {
        path: ':microserviceId',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/microservices/details/MicroserviceDetailsPage'),
      },
      {
        path: ':microserviceId/edit',
        lazy: () =>
          import(
            /* webpackPrefetch: true */ /* webpackPrefetch: true */ 'app/pages/microservices/details/overview/MicroserviceEditOverviewPage'
          ),
      },
    ],
  },
  {
    path: '/microservices/:microserviceId',
    lazy: () => import(/* webpackPrefetch: true */ 'app/pages/microservices/details/EnabledMicroservicePage'),
  },
  {
    path: '/microservices-events',
    lazy: () => import(/* webpackPrefetch: true */ 'app/pages/microservices/audit-log/MicroservicesAuditLogPage'),
  },
  {
    path: '/microservices-events/:microserviceEventId',
    lazy: () =>
      import(
        /* webpackPrefetch: true */ /* webpackPrefetch: true */ 'app/pages/microservices/audit-log/MicroservicesAuditLogDetailsPage'
      ),
  },
]
