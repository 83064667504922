import { IDropdown, IDropdownOption, IDropdownProps, ISearchBox, ISelectableOption, ResponsiveMode, Shimmer, useResponsiveMode } from '@fluentui/react'
import { Business } from '../../../query-client/types'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useOrganizationSelection } from '../../../globals/useOrganizationSelection'
import { useTranslation } from 'react-i18next'
import './label/SidebarOrganizationSelectorLabel.component.scss'
import { SidebarOrganizationSelectorLabel } from './label/SidebarOrganizationSelectorLabel.component'
import {
  SearchableDropdownAlt,
  SearchBoxPosition,
} from 'app/components/SearchableDropdown/SearchableDropdownAlt.component'
import useMediaQuery from '../../../hooks/utils/useMediaQuery'
import { MOBILE_QUERY, TABLET_QUERY } from '../../../config/media-query'
import {
  renderOrganizationSelectorDropdownItem
} from "../../../pages/common/OrganizationSelectorDropdownItemRenderer/OrganizationSelectorDropdownItemRenderer";
import {
  getSidebarOrganizationSelectorDropdownStyles
} from "./searchable-dropdown/SidebarOrganizationSelectorDropdownStyles";
import { renderOrganizationSelectorPlaceholder } from 'app/pages/common/OrganizationSelectorDropdownItemRenderer/OrganizationSelectorDropdownPlaceholderRenderer'
import { SelectableOrganization } from 'app/hooks/organization/useGetOrganizationsForSelection'

interface Props {
  organizationList?: SelectableOrganization[]
  userOrganization?: Business
  withLabel?: boolean
}

export const SidebarOrganizationSelector = ({ organizationList, userOrganization, withLabel }: Props) => {
  const { t } = useTranslation()
  const fluentUIResponsiveMode = useResponsiveMode(React.createRef())
  const { selectedOrganizationId, setSelectedOrganizationId, selectedOrganization } = useOrganizationSelection()
  const [dropdownOptionsState, setDropdownOptionsState] = useState<IDropdownOption[]>([])
  const dropdownRef = useRef<IDropdown | null>(null)
  const searchRef = useRef<ISearchBox | null>(null)
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const isResponsiveMobileMode = () => fluentUIResponsiveMode <= ResponsiveMode.medium

  const buildDropdownOptions = (organizationList: SelectableOrganization[] | undefined) => {
    if (!organizationList) {
      return setDropdownOptionsState([])
    }
    const dropdownOptions = organizationList
      .map((organization) => {
        return {
          key: organization._id,
          text: organization.name,
          data: {
            isTrial: organization.isTrial,
            type: organization.type,
            isCurrent: organization._id === selectedOrganizationId,
          },
        }
      })
      .filter((option) => option.key !== selectedOrganizationId)
    setDropdownOptionsState(dropdownOptions)
  }

  useEffect(() => {
    buildDropdownOptions(
      organizationList?.filter((organization) => {
        return organization.active || organization._id === selectedOrganizationId
      }) ?? []
    )
  }, [organizationList, selectedOrganizationId])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
        event.preventDefault()
        dropdownRef.current?.focus(true)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const handleOnDropdownChange = (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {

    if (!organizationList || !option) {
      return
    }

    const selectedOrganization = organizationList.find((organization) => organization._id === option.key)
    if (!selectedOrganization) {
      return
    }
    setSelectedOrganizationId(selectedOrganization._id)

    dropdownRef.current?.dismissMenu()
  }

  const handleOnDropdownRenderOption = (
    props?: ISelectableOption,
    _?: (props?: ISelectableOption) => JSX.Element | null
  ): JSX.Element | null => {
    return renderOrganizationSelectorDropdownItem(
      t,
      props,
      userOrganization,
      props?.data?.isTrial,
      !isResponsiveMobileMode(),
      false,
      searchRef
    )
  }

  const handleOnDropdownRenderTitle = (
    props?: ISelectableOption[],
    _?: (props?: ISelectableOption[]) => JSX.Element | null
  ): JSX.Element | null => {
    return renderOrganizationSelectorDropdownItem(
      t,
      props?.[0],
      userOrganization,
      props?.[0].data?.isTrial,
      true,
      isResponsiveMobileMode()
    )
  }

  const handleOnDropdownRenderPlaceholder = (
    props?: IDropdownProps | undefined, defaultRender?: any) => {
    return renderOrganizationSelectorPlaceholder(t, isResponsiveMobileMode(), selectedOrganization, userOrganization!)
  }

  const shouldNotRenderOrganizationSelector = (): boolean => {
    return false
  }

  if (shouldNotRenderOrganizationSelector()) {
    return null
  }

  return (
    <>

      {selectedOrganization ?
        <>
          {withLabel && <SidebarOrganizationSelectorLabel translator={t} />}
          <SearchableDropdownAlt
            dropdownProps={{
              selectedKey: selectedOrganizationId,
              defaultSelectedKey: selectedOrganizationId,
              options: dropdownOptionsState.filter((option) => option.key !== selectedOrganizationId),
              onRenderOption: handleOnDropdownRenderOption,
              onRenderTitle: handleOnDropdownRenderTitle,
              onChange: handleOnDropdownChange,
              styles: getSidebarOrganizationSelectorDropdownStyles(isResponsiveMobileMode()),
              onRenderCaretDown: isMobile || isTablet ? () => null : undefined,
              onRenderPlaceholder: handleOnDropdownRenderPlaceholder,
            }}
            searchBoxProps={{
              placeholder: t('organizations:SEARCH_PLACEHOLDER'),
            }}
            searchBoxPosition={SearchBoxPosition.TOP}
            fixedSearchBox={!isResponsiveMobileMode()}
            componentRef={dropdownRef}
            searchRef={searchRef}
          />
        </> : <></>
      }
    </>
  )
}
