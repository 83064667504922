import { FeaturedINavLinkGroup } from '../components/sidebar/Sidebar.elements'
import { TFunction } from 'react-i18next'
import { FEATURE_NAMES } from '../permissions/FeatureName.enum'
import { useOrganizationSelection } from '../globals/useOrganizationSelection'

export function analyzerLinks(t: TFunction<'translation'>): FeaturedINavLinkGroup {

  const { selectedOrganizationId } = useOrganizationSelection()

  return {
    name: `${t('analyzer:TITLE')}`,
    groupData: {
      icon: 'AnalyzerSidebar',
      isPreview: true,
    },
    collapseByDefault: true,
    links: [
      {
        name: t('analyzer:INSTALLED_APPS.TITLE'),
        url: `/organization/${selectedOrganizationId}/analyzer/installed-apps`,
        key: 'installed-apps',
        feature: FEATURE_NAMES.INSTALLED_APPS_READ,
      },
      {
        name: t('analyzer:LICENSES.TITLE'),
        url: `/organization/${selectedOrganizationId}/analyzer/licenses`,
        key: 'licenses',
        feature: FEATURE_NAMES.LICENSES_READ
      },
    ],
  }
}
