import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import en_general from './locales/en/general/translation.json'
import en_login from './locales/en/login/translation.json'
import en_home from './locales/en/home/translation.json'
import en_products from './locales/en/products/translation.json'
import en_organizations from './locales/en/organizations/translation.json'
import en_organization_details from './locales/en/organization-details/translation.json'
import en_reports from './locales/en/reports/translation.json'
import en_workspace_groups from './locales/en/workspace-groups/translation.json'
import en_user_settings from './locales/en/user-settings/translation.json'
import en_microservices from './locales/en/microservices/translation.json'
import en_user_journey from './locales/en/user-journey/translation.json'
import en_flows from './locales/en/flows/translation.json'
import en_efficiency_operations from './locales/en/efficiency-operations/translation.json'
import en_patch_management from './locales/en/patch-management/translation.json'
import en_user_logins from './locales/en/user-logins/translation.json'
import en_analyzer from './locales/en/analyzer/translation.json'
import en_odin_reports from './locales/en/odin-reports/translation.json'

import es_general from './locales/es-ES/general/translation.json'
import es_login from './locales/es-ES/login/translation.json'
import es_home from './locales/es-ES/home/translation.json'
import es_products from './locales/es-ES/products/translation.json'
import es_organizations from './locales/es-ES/organizations/translation.json'
import es_organization_details from './locales/es-ES/organization-details/translation.json'
import es_reports from './locales/es-ES/reports/translation.json'
import es_workspace_groups from './locales/es-ES/workspace-groups/translation.json'
import es_user_settings from './locales/es-ES/user-settings/translation.json'
import es_microservices from './locales/es-ES/microservices/translation.json'
import es_user_journey from './locales/es-ES/user-journey/translation.json'
import es_flows from './locales/es-ES/flows/translation.json'
import es_efficiency_operations from './locales/es-ES/efficiency-operations/translation.json'
import es_patch_management from './locales/es-ES/patch-management/translation.json'
import es_user_logins from './locales/es-ES/user-logins/translation.json'
import es_analyzer from './locales/es-ES/analyzer/translation.json'
import es_odin_reports from './locales/es-ES/odin-reports/translation.json'

import pt_general from './locales/pt-BR/general/translation.json'
import pt_login from './locales/pt-BR/login/translation.json'
import pt_home from './locales/pt-BR/home/translation.json'
import pt_products from './locales/pt-BR/products/translation.json'
import pt_organizations from './locales/pt-BR/organizations/translation.json'
import pt_organization_details from './locales/pt-BR/organization-details/translation.json'
import pt_reports from './locales/pt-BR/reports/translation.json'
import pt_workspace_groups from './locales/pt-BR/workspace-groups/translation.json'
import pt_user_settings from './locales/pt-BR/user-settings/translation.json'
import pt_microservices from './locales/pt-BR/microservices/translation.json'
import pt_user_journey from './locales/pt-BR/user-journey/translation.json'
import pt_flows from './locales/pt-BR/flows/translation.json'
import pt_efficiency_operations from './locales/pt-BR/efficiency-operations/translation.json'
import pt_patch_management from './locales/pt-BR/patch-management/translation.json'
import pt_user_logins from './locales/pt-BR/user-logins/translation.json'
import pt_odin_reports from './locales/pt-BR/odin-reports/translation.json'

import ca_general from './locales/ca/general/translation.json'
import ca_login from './locales/ca/login/translation.json'
import ca_home from './locales/ca/home/translation.json'
import ca_products from './locales/ca/products/translation.json'
import ca_organizations from './locales/ca/organizations/translation.json'
import ca_organization_details from './locales/ca/organization-details/translation.json'
import ca_reports from './locales/ca/reports/translation.json'
import ca_workspace_groups from './locales/ca/workspace-groups/translation.json'
import ca_user_settings from './locales/ca/user-settings/translation.json'
import ca_microservices from './locales/ca/microservices/translation.json'
import ca_user_journey from './locales/ca/user-journey/translation.json'
import ca_flows from './locales/ca/flows/translation.json'
import ca_efficiency_operations from './locales/ca/efficiency-operations/translation.json'
import ca_patch_management from './locales/ca/patch-management/translation.json'
import ca_user_logins from './locales/ca/user-logins/translation.json'
import ca_odin_reports from './locales/ca/odin-reports/translation.json'

import eu_general from './locales/eu/general/translation.json'
import eu_login from './locales/eu/login/translation.json'
import eu_home from './locales/eu/home/translation.json'
import eu_products from './locales/eu/products/translation.json'
import eu_organizations from './locales/eu/organizations/translation.json'
import eu_organization_details from './locales/eu/organization-details/translation.json'
import eu_reports from './locales/eu/reports/translation.json'
import eu_workspace_groups from './locales/eu/workspace-groups/translation.json'
import eu_user_settings from './locales/eu/user-settings/translation.json'
import eu_microservices from './locales/eu/microservices/translation.json'
import eu_user_journey from './locales/eu/user-journey/translation.json'
import eu_flows from './locales/eu/flows/translation.json'
import eu_efficiency_operations from './locales/eu/efficiency-operations/translation.json'
import eu_patch_management from './locales/eu/patch-management/translation.json'
import eu_user_logins from './locales/eu/user-logins/translation.json'
import eu_odin_reports from './locales/eu/odin-reports/translation.json'

export const resources = {
  en: {
    general: en_general,
    login: en_login,
    home: en_home,
    products: en_products,
    organizations: en_organizations,
    organization_details: en_organization_details,
    reports: en_reports,
    workspace_groups: en_workspace_groups,
    user_settings: en_user_settings,
    microservices: en_microservices,
    user_journey: en_user_journey,
    flows: en_flows,
    efficiency_operations: en_efficiency_operations,
    patch_management: en_patch_management,
    user_logins: en_user_logins,
    analyzer: en_analyzer,
    odin_reports: en_odin_reports,
  },
  es: {
    general: es_general,
    login: es_login,
    home: es_home,
    products: es_products,
    organizations: es_organizations,
    organization_details: es_organization_details,
    reports: es_reports,
    workspace_groups: es_workspace_groups,
    user_settings: es_user_settings,
    microservices: es_microservices,
    user_journey: es_user_journey,
    flows: es_flows,
    efficiency_operations: es_efficiency_operations,
    patch_management: es_patch_management,
    user_logins: es_user_logins,
    analyzer: es_analyzer,
    odin_reports: es_odin_reports,
  },
  pt: {
    general: pt_general,
    login: pt_login,
    home: pt_home,
    products: pt_products,
    organizations: pt_organizations,
    organization_details: pt_organization_details,
    reports: pt_reports,
    workspace_groups: pt_workspace_groups,
    user_settings: pt_user_settings,
    microservices: pt_microservices,
    user_journey: pt_user_journey,
    flows: pt_flows,
    efficiency_operations: pt_efficiency_operations,
    patch_management: pt_patch_management,
    user_logins: pt_user_logins,
    odin_reports: pt_odin_reports,
  },
  ca: {
    general: ca_general,
    login: ca_login,
    home: ca_home,
    products: ca_products,
    organizations: ca_organizations,
    organization_details: ca_organization_details,
    reports: ca_reports,
    workspace_groups: ca_workspace_groups,
    user_settings: ca_user_settings,
    microservices: ca_microservices,
    user_journey: ca_user_journey,
    flows: ca_flows,
    efficiency_operations: ca_efficiency_operations,
    patch_management: ca_patch_management,
    user_logins: ca_user_logins,
    odin_reports: ca_odin_reports,
  },
  eu: {
    general: eu_general,
    login: eu_login,
    home: eu_home,
    products: eu_products,
    organizations: eu_organizations,
    organization_details: eu_organization_details,
    reports: eu_reports,
    workspace_groups: eu_workspace_groups,
    user_settings: eu_user_settings,
    microservices: eu_microservices,
    user_journey: eu_user_journey,
    flows: eu_flows,
    efficiency_operations: eu_efficiency_operations,
    patch_management: eu_patch_management,
    user_logins: eu_user_logins,
    odin_reports: eu_odin_reports,
  },
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    supportedLngs: ['en', 'es', 'pt', 'ca', 'eu'],
  })
