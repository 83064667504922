import { FeaturitEvent } from 'app/globals/context/Featurit.enum'
import { ViewAsEndUser, ViewAsEndUserKey } from './types'
import { useLocalStorage } from 'app/hooks/utils/useLocalStorage'

const getViewAsEndUserFromLocalStorage = (): ViewAsEndUser => {
  const found = localStorage.getItem(FeaturitEvent.VIEW_AS_END_USER)

  if (!found || isNaN(parseInt(found))) {
    return ViewAsEndUser.OFF
  }

  if (!Object.values(ViewAsEndUser).includes(parseInt(found))) {
    return ViewAsEndUser.OFF
  }

  const viewAsEndUserValue = ViewAsEndUser[parseInt(found) as ViewAsEndUser] as ViewAsEndUserKey

  return ViewAsEndUser[viewAsEndUserValue]
}

export const useViewAsEndUserStorage = () => {
  const found = getViewAsEndUserFromLocalStorage()

  const [viewAsEndUser, setViewAsEndUser] = useLocalStorage<ViewAsEndUser>(
    FeaturitEvent.VIEW_AS_END_USER,
    found ?? ViewAsEndUser.OFF
  )

  return {
    viewAsEndUser,
    setViewAsEndUser: async (v: ViewAsEndUser) => {
      setViewAsEndUser(v)
      await new Promise<void>((res) => setTimeout(res, 500))
      location.reload()
    },
  }
}
