import { ModuleRegistry } from '@ag-grid-community/core'
import { LicenseManager } from '@ag-grid-enterprise/core'

import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'

import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter'
import { MenuModule } from '@ag-grid-enterprise/menu'

export function handleAgGridLicense(license: string) {
  ModuleRegistry.registerModules([AdvancedFilterModule, ServerSideRowModelModule, MenuModule])

  LicenseManager.setLicenseKey(license)
}
