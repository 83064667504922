import React, { PropsWithChildren, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import { RouterProvider } from 'react-router-dom'
import { initializeIcons, registerIcons, ThemeProvider } from '@fluentui/react'
import { theme } from './app/config/theme'
import './app/i18n/i18n'
import { IcoFlexx, IcoFlexxWhite, OperationsSidebarIcon } from './app/components/Icons'
import { QueryClientProvider } from '@tanstack/react-query'
import queryClient from './app/query-client/queryClient'
import 'flexboxgrid/dist/flexboxgrid.min.css'
import { Router } from './app/routing/Router'
import { Auth0Provider } from '@auth0/auth0-react'
import { GlobalConfigProvider, useGlobalConfig } from 'app/globals/context/GlobalConfigContext'
import { DoptUsersProvider } from '@dopt/react-users'
import { LinuxLogo32Icon } from '@fluentui/react-icons-mdl2'
import { AnalyzerSidebarIcon } from './app/analyzer/components/icons/AnalyzerSidebarIcon'
import { FeaturitProvider } from './app/globals/context/FeaturitProvider'
import { unregister } from 'serviceWorkerRegistration'
import BootstrapIcon from 'app/components/BootstrapIcon/BootstrapIcon'
import { handleAgGridLicense } from 'app/components/ag-grid/handleAgGridLicense'
import { agGridConfig } from 'app/components/ag-grid/agGridConfig'

handleAgGridLicense(agGridConfig.agGridLicense!)

initializeIcons()
registerIcons({
  icons: {
    IcoFlexx: <IcoFlexx />,
    IcoFlexxWhite: <IcoFlexxWhite />,
    LinuxLogo: <LinuxLogo32Icon />,
    AnalyzerSidebar: <AnalyzerSidebarIcon />,
    Operations: <OperationsSidebarIcon />,
  },
})

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container!)

const Auth0: React.FC<PropsWithChildren> = ({ children }) => {
  const globalConfig = useGlobalConfig()
  return (
    <Auth0Provider
      domain={globalConfig?.auth0Domain ?? ''}
      clientId={globalConfig?.auth0ClientId ?? ''}
      legacySameSiteCookie
      authorizationParams={{ redirect_uri: window.location.origin, prompt: 'select_account' }}
    >
      {children}
    </Auth0Provider>
  )
}
const DoptUsers: React.FC<PropsWithChildren> = ({ children }) => {
  const globalConfig = useGlobalConfig()
  return <DoptUsersProvider apiKey={globalConfig?.doptUsersApiKey ?? ''}>{children}</DoptUsersProvider>
}

root.render(
  <ThemeProvider theme={theme}>
    <Suspense fallback="loading">
      <QueryClientProvider client={queryClient}>
        <GlobalConfigProvider>
          <DoptUsers>
            <Auth0>
              <FeaturitProvider>
                <RouterProvider router={Router} />
              </FeaturitProvider>
            </Auth0>
          </DoptUsers>
        </GlobalConfigProvider>
      </QueryClientProvider>
    </Suspense>
  </ThemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
unregister()
